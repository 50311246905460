<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-8">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Detail Info</p>
        <div class="field">
          <label class="label">Sent To</label>
          <span>{{ value.sendTo }}</span>
        </div>
        <div class="field">
          <label class="label">Cc</label>
          <span>{{ value.cc }}</span>
        </div>
        <div class="field">
          <label class="label">Message</label>
          <span style="white-space: pre-wrap;">{{ value.message | replaceBr }}</span>
        </div>
        <div class="field">
          <label class="label">Sent On</label>
          <span>{{ $filters.formatDateTimeLocale(value.sentOn, $userInfo.locale) }}</span>
        </div>
        <div class="field">
          <label class="label">Type</label>
          <span>{{ value.assetType }}</span>
        </div>
        <div class="field">
          <label class="label">Status</label>
          <span>{{ value.status }}</span>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
// import EmailSmsLogService from './EmailSmsLogService'
import StoreMixin from './storeMixin'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'EmailsmslogDetail',
  // inject: ['$vv'],
  components: {},
  filters: {
    replaceBr(value) {
      return value.replace(/<br\s*\/?>/mg, '\n')
    }
  },
  mixins: [DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      // message: this.value.message.replace(/<br\s*\/?>/mg, '\n') //this.value.message.replace(/(<([^>]+)>)/ig, '\r\n')
    }
  },
  computed: {
    // $v() {
    //   return this.$vv || this.$v
    // }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        alert(newVal)
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  mounted() {
    // this.$v.detailGroup.$touch()
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
</style>
