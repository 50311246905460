<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="true"
      :show-save="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <emailsmslog-side-menu
          v-if="entity"
          ref="sideMenu" />
      </div>
      <div class="column">
        <router-view
          v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          @entityDelete="onEntityDelete()" />
      </div>
    </div>
    <confirm-modal
      v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Email/SMS Log</p>
      <p slot="text-content">
        Email/SMS Log will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import EmailSmsLogSideMenu from './EmailSmsLogSideMenu'
import EmailSmsLogService from './EmailSmsLogService'
import EmailSmsLogValidation from './EmailSmsLogValidation'
import EmailSmsLogRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { ConfirmModal } from '@/components/BulmaModal'
import EventBus from '@/components/EventBus'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'EmailSmsLogView',
  components: {
    AppDetailHeader,
    [EmailSmsLogSideMenu.name]: EmailSmsLogSideMenu,
    ConfirmModal
  },
  mixins: [EmailSmsLogValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isConfirmModalActive: false,
      deleteAndSave: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    // validateError() {
    //   return this.$v.$error || this.$v.detailGroup.$error
    // }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    // this.clearSessionStorage()
    // this.clearSnapshots(this.entity.id)
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.id)
    }
    this.getEntity()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          // this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          // this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.id)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.id)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Email/SMS Log'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      this.snapshotDiff
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        await EmailSmsLogService.deleteEntity(this.entity.id)
        // await this.removeStoreItem(this.entity.id)
        this.$notification.success('Email/SMS Log', 'Email/SMS Log deleted')

        this.$router.push({
          name: EmailSmsLogRoutes.EmailSmsLogListView.name,
          filter: this.filter,
          query: {
            filter: this.encodedFilter
          }
        })
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.isDelete = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        // const returnUrl = sessionStorage.getItem(this.returnUrlKey)
        // if it comes from url => redirect after save
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            // cleanup vuex store
            this.clearSnapshots(this.entity.id)
          }
          this.$router.push({
            name: EmailSmsLogRoutes.EmailSmsLogListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|emailsmslog|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|emailsmslog|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|emailsmslog|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|emailsmslog|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: EmailSmsLogRoutes.EmailSmsLogDetail.name,
        params: { id: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.id)
      this.clearSessionStorage()
      this.clearRouteMeta()
      next()
    }
  }
}
</script>
